.Container {
  background: #333;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.SelectButton {
  width: 200px;
  height: 180px;
  line-height: 200px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background: #f74d4d;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f74d4d), color-stop(100%, #f86569));
  background-image: -moz-gradient(linear, left top, left bottom, color-stop(0%, #f74d4d), color-stop(100%, #f86569));
  box-shadow: 0 15px #e24f4f;
}

.SelectButton:active {
  box-shadow: 0 0 #e24f4f;
  -webkit-transform: translate(0px, 15px);
  -moz-transform: translate(0px, 15px);
  -ms-transform: translate(0px, 15px);
  -o-transform: translate(0px, 15px);
  -webkit-transition: 0.1s all ease-out;
  -moz-transition: 0.1s all ease-out;
  -ms-transition: 0.1s all ease-out;
  -o-transition: 0.1s all ease-out;
  transition: 0.1s all ease-out;
}

.HintText {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  color: #ffffff;
  font-size: 24px;
  opacity: 0.45;
  padding-top: 40px;
  display: flex;
  text-align: center;
}

.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
